/* home.css */

.home-container {
    min-height: 100vh;
    background-color: white;
    color: #1f2937;
    font-family: sans-serif;
    margin: 0 auto;
  }

  .home-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background-color: #2563eb;
    color: white;
    padding: 1rem;
  }

  .home-navbar a {
    color: white;
  }
  
  .home-header {
    text-align: center;
    padding: 2.5rem 0;
  }
  
  .home-title {
    font-size: 2.5rem;
    font-weight: 300;
    color: #2563eb;
  }

  .home-title span {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2563eb;
  }
  
  .home-subtitle {
    margin-top: 1rem;
    font-size: 1.125rem;
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .home-input-group {
    margin-top: 1.5rem;
  }
  
  .home-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 24rem;
    display: block;
    margin: 0 auto;
    border-radius: 0.375rem;
  }
  
  .home-button {
    margin-top: 1rem;
    background-color: #2563eb;
    color: white;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .home-button.large {
    font-size: 1.125rem;
    padding: 0.75rem 2rem;
  }
  
  .home-section {
    padding: 2.5rem 0;
    text-align: center;
  }
  
  .home-section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  
  .home-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    max-width: 72rem;
    margin: 0 auto;
  }
  
  @media (min-width: 768px) {
    .home-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .home-card {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    background: white;
  }
  
  .home-card-title {
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  
  .home-cta {
    padding: 2.5rem 1rem;
    background-color: #eff6ff;
    text-align: center;
  }
  
  .home-cta-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .home-cta-text {
    margin-bottom: 1.5rem;
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  }

  /* Business Carousel */
  .business-carousel {
    margin: 2rem 1rem;
    text-align: center;
  }

  .business-carousel h2 {
    margin-bottom: 1em;
  }

  .business-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .business-card {
    flex: 1 1 200px;
    min-width: 200px;
    max-width: 220px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    padding: 1rem;
    text-align: left;
    transition: box-shadow 0.2s ease;
  }

  .business-card:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }

  .business-link {
    text-decoration: none;
    color: #333;
  }

  .business-link h3 {
    margin: 0 0 0.5rem 0;
  }

  .business-link p {
    margin: 0;
    font-size: 0.9rem;
  }

/* Footer */
  
  .home-footer {
    padding: 1.5rem 0;
    text-align: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  